import { assign, fromPromise, sendTo, setup } from "xstate";

export const ValidateMachine = setup({
	types: {
		input: {} as {
			initialValidator?: boolean;
			validator: () => Promise<boolean>;
		},
		context: {} as {
			validated: boolean;
			validator: () => Promise<boolean>;
			timesValidated: number;
		},
		events: {} as
			| { type: "validate" }
			| { type: "reset" }
			| { type: "replace.validator"; validator: () => Promise<boolean> },
	},
	actions: {
		"Increment Times Validated": assign({
			timesValidated: ({ context }) => context.timesValidated + 1,
		}),
	},
	actors: {
		validateActor: fromPromise(
			async ({
				input: { validator },
			}: {
				input: { validator: () => Promise<boolean> };
			}): Promise<boolean> => {
				if (!validator) {
					return true;
				}
				const output = await validator();
				return output;
			},
		),
	},
	guards: {
		validatorIsTrue: function ({ context }) {
			return context.validated;
		},
	},
	delays: {
		"Incremental Backoff Delay": ({ context }) => {
			return Math.pow(2, context.timesValidated) * 100;
		},
	},
}).createMachine({
	context: ({ input }) => {
		return {
			validated: input.initialValidator || false,
			validator: input.validator,
			timesValidated: 0,
		};
	},
	id: "Validate Machine",
	initial: "INITIAL",
	on: {
		"replace.validator": {
			actions: assign(({ event }) => {
				return {
					validator: event.validator,
				};
			}),
		},
	},
	states: {
		INITIAL: {
			always: [
				{
					target: "VALID",
					guard: "validatorIsTrue",
				},
				{
					target: "INVALID",
				},
			],
		},
		INVALID: {
			entry: [
				sendTo(
					({ system }) => {
						return system.get("validate-overall-machine");
					},
					({ self }) => {
						return {
							type: "report",
							childId: self.id,
							valid: false,
						};
					},
				),
			],
			after: {
				["Incremental Backoff Delay"]: "VALIDATING", // Hack - this is to get the machine to always try to validate itself because it wos a race condition in the FM/AM stations. They weren't getting the render required to validate.
			},
			on: {
				validate: [
					{
						target: "VALIDATING",
					},
				],
			},
		},
		VALIDATING_EVALUATE: {
			always: [
				{
					target: "VALID",
					guard: "validatorIsTrue",
				},
				{
					target: "INVALID",
				},
			],
		},
		VALIDATING: {
			entry: ["Increment Times Validated"],
			invoke: {
				src: "validateActor",
				input: ({ context }) => {
					return { validator: context.validator };
				},
				onDone: [
					{
						target: "VALIDATING_EVALUATE",
						actions: assign(({ event }) => {
							// console.log("VALIDATING_EVALUATE", event);
							return {
								validated: !!event?.output,
							};
						}),
					},
				],
				onError: [
					{
						actions: assign({ validated: false }),
						target: "INVALID",
					},
				],
			},
		},
		VALID: {
			entry: [
				sendTo(
					({ system }) => {
						return system.get("validate-overall-machine");
					},
					({ self }) => {
						return {
							type: "report",
							childId: self.id,
							valid: true,
						};
					},
				),
			],
			on: {
				validate: {
					target: "VALIDATING",
				},
			},
		},
	},
});
