import { produce } from "immer";
import { ReactNode } from "react";
import { ActorRefFrom, assign, setup, stopChild } from "xstate";
import { DumbModalMachine } from "~zd-machines/DumbModalMachine.tsx";

type ModalActorRef = ActorRefFrom<typeof DumbModalMachine>;

export const DumbModalSystem = setup({
	types: {
		context: {} as { modals: Record<string, ModalActorRef> },
		events: {} as
			| { type: "reset" }
			| {
					type: "create";
					childId: string;
					component: ({ dismissFn }) => ReactNode;
			  }
			| { type: "remove"; childId: string },
	},
	actors: {
		dumbModalMachine: DumbModalMachine,
	},
	actions: {
		clear: assign(() => {
			return {
				modals: {},
			};
		}),
		create: assign(
			(
				{ context, spawn },
				{
					childId,
					component,
				}: { childId: string; component: ({ dismissFn }) => ReactNode },
			) => {
				const newModals = produce(context.modals, (draft) => {
					if (!draft[childId]) {
						const actorRef = spawn("dumbModalMachine", {
							id: childId,
							input: {
								component,
							},
						});

						draft[childId] = actorRef;
					}
				});
				return {
					modals: newModals,
				};
			},
		),
		remove: assign(({ context }, { childId }: { childId: string }) => {
			const newConditions = produce(context.modals, (draft) => {
				const actor = draft[childId];
				// console.log(actor, "actor");
				delete draft[childId];
			});

			// console.log(newConditions, "newConditions");
			return {
				modals: newConditions,
			};
		}),
	},
}).createMachine({
	context: {
		modals: {},
	},
	id: "Dumb Modal System",
	initial: "DISPLAY",
	on: {
		create: {
			actions: {
				type: "create",
				params: ({ event }) => {
					return {
						childId: event.childId,
						component: event.component,
					};
				},
			},
		},
		remove: {
			actions: [
				stopChild(({ event }) => event.childId),
				{
					type: "remove",
					params: ({ event }) => {
						return {
							childId: event.childId,
						};
					},
				},
			],
		},
		reset: {
			target: ".DISPLAY",
			actions: ["clear"],
		},
	},
	states: {
		DISPLAY: {},
	},
});
