import { produce } from "immer";
import { type ActorRefFrom, assign, emit, setup, stopChild } from "xstate";
import { ValidateMachine } from "~zd-machines/ValidateMachine.ts";

type ValidateActorRef = ActorRefFrom<typeof ValidateMachine>;

type ValidatorRef = {
	valid: boolean;
	actorRef: ValidateActorRef;
};
export const ValidationManagerMachine = setup({
	types: {
		context: {} as { validators: Record<string, ValidatorRef> },
		events: {} as
			| { type: "reset" }
			| { type: "validate" }
			| {
					type: "create";
					childId: string;
					input: { validator: () => Promise<boolean> };
			  }
			| {
					type: "replaceValidator";
					childId: string;
					input: { validator: () => Promise<boolean> };
			  }
			| { type: "remove"; childId: string },
	},
	actors: {},
	actions: {
		emitValid: emit({ type: "ValidationManagerMachineIsValid" }),
		emitInvalid: emit({ type: "ValidationManagerMachineIsInvalid" }),
		report: assign(
			(
				{ context },
				{ childId, valid }: { childId: string; valid: boolean },
			) => {
				// console.log("report", childId, valid);
				const newChildren = produce(context.validators, (draft) => {
					if (draft[childId]) {
						const { actorRef } = draft[childId];

						draft[childId] = {
							valid: valid,
							actorRef,
						};
					}
				});

				// console.log("newChildren", newChildren);
				return {
					validators: newChildren,
				};
			},
		),
		askAllToReport: ({ context }) => {
			const values = Object.values(context.validators);
			if (values.length === 0) {
				return true;
			}
			for (const v of values) {
				const { actorRef } = v;
				actorRef.send({ type: "validate" });
			}
		},
		clear: assign(() => {
			return {
				validators: {},
			};
		}),
		replaceValidator: (
			{ context },
			{
				childId,
				input,
			}: { childId: string; input: { validator: () => Promise<boolean> } },
		) => {
			const v = context.validators[childId];
			if (v) {
				v.actorRef.send({
					type: "replace.validator",
					validator: input.validator,
				});
			}
		},
		create: assign(
			(
				{ context, spawn },
				{
					childId,
					input,
				}: { childId: string; input: { validator: () => Promise<boolean> } },
			) => {
				const newChildren = produce(context.validators, (draft) => {
					if (!draft[childId]) {
						const actorRef = spawn(ValidateMachine, {
							id: childId,
							input,
						});
						draft[childId] = {
							valid: actorRef.getSnapshot().matches("VALID"),
							actorRef,
						};
					}
				});
				return {
					validators: newChildren,
				};
			},
		),
		remove: assign(({ context }, { childId }: { childId: string }) => {
			const newConditions = produce(context.validators, (draft) => {
				delete draft[childId];
			});
			return {
				validators: newConditions,
			};
		}),
	},

	guards: {
		evaluateAllValidators: ({ context }) => {
			// Add your guard condition here
			const values = Object.values(context.validators);

			console.log("evaluateAllValidators", values);
			if (values.length === 0) {
				return true;
			}

			const evaluated = values.map((actor) => actor.valid);

			// console.log("evaluated", evaluated);

			return evaluated.every(Boolean);
		},
	},
}).createMachine({
	context: {
		validators: {},
	},
	id: "Validate Overall Machine",
	initial: "INVALID",
	on: {
		create: {
			actions: {
				type: "create",
				params: ({ event }) => {
					return {
						childId: event.childId,
						input: event.input,
					};
				},
			},
		},
		remove: {
			actions: [
				stopChild(({ event }) => event.childId),
				{
					type: "remove",
					params: ({ event }) => {
						return {
							childId: event.childId,
						};
					},
				},
			],
		},
		report: {
			target: ".VALIDATING",
			actions: [
				{
					type: "report",
					params: ({ event }) => {
						return {
							childId: event.childId,
							valid: event.valid,
						};
					},
				},
			],
		},
		replaceValidator: {
			actions: {
				type: "replaceValidator",
				params: ({ event }) => {
					return {
						childId: event.childId,
						input: event.input,
					};
				},
			},
		},
		validate: {
			actions: {
				type: "askAllToReport",
			},
		},
		reset: {
			target: ".INVALID",
			actions: ["clear"],
		},
	},
	states: {
		INVALID: {
			entry: ["emitInvalid"],
		},
		VALIDATING: {
			always: [
				{
					target: "VALID",
					guard: {
						type: "evaluateAllValidators",
					},
				},
				{
					target: "INVALID",
				},
			],
		},
		VALID: {
			entry: ["emitValid"],
		},
	},
});
