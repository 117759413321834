import { createActor } from "xstate";
import { DumbModalSystem } from "~zd-machines/DumbModalSystem.ts";
import { ValidationManagerMachine } from "~zd-machines/ValidationManagerMachine.ts";

export const ValidationManagerMachineRef = createActor(
	ValidationManagerMachine,
	{
		systemId: "validate-overall-machine",
	},
);

ValidationManagerMachineRef.start();

export const DumbModalSystemRef = createActor(DumbModalSystem, {
	systemId: "dumb-modal-system",
});

DumbModalSystemRef.start();
