import React, { ReactNode } from "react";
import { sendTo, setup } from "xstate";

export const DumbModalMachine = setup({
	types: {
		context: {} as { component: ({ dismissFn }) => ReactNode },
		input: {} as { component: ({ dismissFn }) => ReactNode },
		events: {} as { type: "dismiss" },
	},
}).createMachine({
	context: ({ input: { component } }) => {
		return {
			component,
		};
	},
	initial: "DISPLAYED",
	states: {
		DISPLAYED: {
			on: {
				dismiss: {
					target: "END",
				},
			},
		},
		END: {
			entry: [
				() => {
					console.log("DumbModalMachine END");
				},
				sendTo(
					({ system }) => {
						return system.get("dumb-modal-system");
					},
					({ self }) => {
						return {
							type: "remove",
							childId: self.id,
						};
					},
				),
			],
			type: "final",
		},
	},
});
